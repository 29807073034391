import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useMotionValue } from 'framer-motion';

import { Dot } from './styles';

const Cursor = () => {
  const ref = useRef();
  const top = useMotionValue(-20);
  const left = useMotionValue(-20);
  const timer = useRef();
  const [withScale, setWithScale] = useState(true);

  const handleMouseMove = useCallback(
    ({ clientX, clientY, target }) => {
      top.set(clientY);
      left.set(clientX);

      if (!timer.current) {
        setWithScale(false);

        timer.current = setTimeout(() => {
          setWithScale(true);

          timer.current = null;
        }, 300);
      }
    },
    [left, top]
  );

  useEffect(() => {
    if ('ontouchstart' in window || navigator.msMaxTouchPoints) return () => null;

    const { current } = timer;

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      if (current) clearInterval(current);

      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [handleMouseMove]);

  return <Dot ref={ref} $withScale={withScale} style={{ left, top }} />;
};

export default Cursor;
