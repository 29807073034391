import { createGlobalStyle } from 'styled-components';

import bookWoff from '@/assets/fonts/RecklessNeue-Book.woff';
import bookWoff2 from '@/assets/fonts/RecklessNeue-Book.woff2';
import lightWoff from '@/assets/fonts/RecklessNeue-Light.woff';
import lightWoff2 from '@/assets/fonts/RecklessNeue-Light.woff2';
import regularWoff from '@/assets/fonts/RecklessNeue-Regular.woff';
import regularWoff2 from '@/assets/fonts/RecklessNeue-Regular.woff2';

import { fontStyles } from '.';

export default createGlobalStyle`
  @font-face {
    font-family: RNBook;
    font-display: swap;
    src: url(${bookWoff2}) format("woff2"), url(${bookWoff}) format("woff");
  }
  @font-face {
    font-family: RNLight;
    font-display: swap;
    src: url(${lightWoff2}) format("woff2"), url(${lightWoff}) format("woff");
  }
  @font-face {
    font-family: RNRegular;
    font-display: swap;
    src: url(${regularWoff2}) format("woff2"), url(${regularWoff}) format("woff");
  }
  html {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    min-width: 350px;
    scroll-behavior: smooth;
  }
  body, #___gatsby, #gatsby-focus-wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  main {
    flex: 1 0 auto;
    width: 100%;
    background-color: var(--color-white);
  }
  button, select {
    border: none;
    border-radius: 0;
    background: none;
    outline: none;
  }
  input, textarea {
    outline: none;
    border-radius: 0;
    background: none;
    padding: 0;
  }
  * {
    ${fontStyles[24]}
    color: var(--color-black);
    margin: 0;
    padding: 0;
    appearance: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: none;
  }
  &:root {
    --color-white: #fff;
    --color-black: #000;
    --color-red: #ff2525;
    --color-blue: #006dff;
  }
`;
