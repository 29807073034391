import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link as DefLink } from 'gatsby';

import { fontStyles } from '@/theme';

export const Container = styled.header`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-black);
`;

export const TitleWrap = styled(DefLink)`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  width: 100%;
  text-decoration: none;
  overflow: hidden;
`;

export const Title = styled.h1`
  ${fontStyles[277]}
  line-height: 0.8;
  color: var(--color-black);
  &:first-of-type {
    margin: 0 0.1em 0 0.05em;
  }
  &:last-of-type {
    margin: 0 0.1em;
  }
  @media (max-width: 1500px) {
    font-size: calc(100vw * 277 / 1500);
    letter-spacing: calc(-100vw * 15 / 1500);
  }
  @media (max-width: 350px) {
    font-size: 65px;
  }
`;

export const DotBox = styled(motion.div).attrs(() => ({
  animate: { height: 'auto' },
  transition: { duration: 0.5 },
}))`
  width: 35px;
  height: 35px;
  position: relative;
  margin: 21px 0 13px;
  @media (max-width: 1500px) {
    width: calc(100vw * 35 / 1500);
    height: calc(100vw * 35 / 1500);
    margin: calc(100vw * 21 / 1500) 0 calc(100vw * 13 / 1500);
  }
  @media (max-width: 350px) {
    width: 8px;
    height: 8px;
    margin: 5px 0 3px;
  }
`;

export const Dot = styled.div`
  width: 100%;
  padding-top: 100%;
  border-radius: 50%;
  background-color: var(--color-blue);
  position: absolute;
  left: 0;
  top: ${({ i }) => i && `calc(50% * ${i})`};
  transform: ${({ i }) => i && `translateY(calc(-50% * ${i}))`};
  transition: background-color 0.3s;
  @media (hover) {
    &:hover {
      background-color: var(--color-black);
    }
  }
`;

export const LinkBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
`;

export const Link = styled(DefLink)`
  display: flex;
  align-items: center;
  height: 27px;
  border-top: 1px solid var(--color-black);
  ${fontStyles[16]}
  text-decoration: none;
  color: var(--color-black);
  @media (hover) and (min-width: 576px) {
    &:hover > div {
      width: ${({ disabled }) => (disabled ? 21 : 77)}px;
    }
  }
  @media (hover) and (max-width: 575px) {
    &:hover > div {
      width: ${({ disabled }) => (disabled ? 24 : 88)}px;
    }
  }
  @media (max-width: 849px) {
    ${fontStyles[18]}
  }
  @media (max-width: 575px) {
    height: auto;
    padding: 4px 0 5px;
  }
`;

export const LinkDotBox = styled.div`
  width: 21px;
  height: 21px;
  margin: 0 3px 0 8px;
  transition: width 0.5s ease-in;
  will-change: width;
  position: relative;
  @media (max-width: 575px) {
    width: 24px;
    height: 24px;
    margin: 0 4px;
  }
`;

export const LinkDot = styled.div`
  width: 21px;
  height: 100%;
  border: 1px solid;
  border-color: ${({ disabled }) => (disabled ? 'var(--color-black)' : 'var(--color-blue)')};
  border-radius: 50%;
  background-color: ${({ disabled }) => (disabled ? 'var(--color-white)' : 'var(--color-blue)')};
  position: absolute;
  top: 0;
  left: ${({ i }) => i && `calc(50% * ${i})`};
  transform: ${({ i }) => i && `translateX(calc(-50% * ${i}))`};
  transition-property: border-color, background-color;
  transition-duration: 0.3s;
  @media (hover) {
    &:hover {
      background-color: ${({ disabled }) => (disabled ? 'var(--color-white)' : 'var(--color-black)')};
      border-color: var(--color-black);
    }
  }
  @media (max-width: 575px) {
    width: 24px;
  }
`;
