import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { threeDots } from '@/utils';

import { Container, TitleWrap, Title, DotBox, Dot, LinkBox, Link, LinkDotBox, LinkDot } from './styles';

const Header = ({ hash }) => {
  const [key, setKey] = useState();
  const { contentJson } = useStaticQuery(graphql`
    {
      contentJson {
        header {
          id
          title
          to
        }
      }
    }
  `);

  useEffect(() => {
    setKey(0);
  }, []);

  return (
    <Container>
      <TitleWrap to="/">
        <Title>TMS</Title>
        <DotBox>
          {threeDots.map((i) => (
            <Dot key={i} i={i} />
          ))}
        </DotBox>
        <Title>Trainer</Title>
      </TitleWrap>
      <LinkBox key={key}>
        {contentJson.header.map(({ id, to, title }) => (
          <Link key={id} to={to} disabled={hash === to}>
            <LinkDotBox>
              {threeDots.map((i) => (
                <LinkDot key={i} i={i} disabled={hash === to} />
              ))}
            </LinkDotBox>
            {title}
          </Link>
        ))}
      </LinkBox>
    </Container>
  );
};

Header.propTypes = { hash: PropTypes.string.isRequired };

export default Header;
