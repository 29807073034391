const fontStyles = {
  277: `
    font-family: "RNLight", sans-serif;
    font-size: 277px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -15px;
  `,
  98: `
    font-family: "RNLight", sans-serif;
    font-size: 93px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: -3.84px;
  `,
  77: `
    font-family: "RNLight", sans-serif;
    font-size: 77px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -3.5px;
  `,
  30: `
    font-family: "RNBook", sans-serif;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: -0.6px;
  `,
  24: `
    font-family: "RNBook", sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.04;
    letter-spacing: -0.6px;
  `,
  18: `
    font-family: "RNBook", sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: -0.4px;
  `,
  16: `
    font-family: "RNBook", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: -0.3px;
  `,
  13: `
    font-family: "RNRegular", sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
  `,
  12: `
    font-family: "RNRegular", sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: -0.13px;
  `,
};

export default fontStyles;
