import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Dot = styled(motion.div).attrs(({ $withScale }) => ({
  animate: {
    scale: $withScale ? 1.5 : 1,
    x: '-50%',
    y: '-50%',
  },
  transition: {
    duration: $withScale ? 0.75 : 0.1,
  },
  initial: {
    scale: 1.3,
    x: '-50%',
    y: '-50%',
  },
}))`
  position: fixed;
  width: 20px;
  height: 20px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  pointer-events: none;
  mix-blend-mode: difference;
  will-change: top, left;
  z-index: 1000;
  @media (hover: none) {
    display: none;
  }
`;
