import React from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import Cursor from './Cursor';

const Layout = ({ hash, children }) => (
  <>
    <Header hash={hash} />
    <main>{children}</main>
    <Cursor />
  </>
);

Layout.propTypes = {
  hash: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Layout;
