const React = require('react');

const GlobalStyle = require('@/theme/GlobalStyles').default;
const Helmet = require('@/components/Helmet').default;
const Layout = require('@/components/Layout').default;

exports.wrapPageElement = ({ element, props: { location } }) => (
  <>
    <Helmet hash={location.hash} />
    <GlobalStyle />
    <Layout hash={location.hash}>{element}</Layout>
  </>
);
