import React, { useMemo } from 'react';
import DefHelmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

const Helmet = ({ hash }) => {
  const { site, file, contentJson } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        file(relativePath: { eq: "open-graph.png" }) {
          publicURL
        }
        contentJson {
          header {
            to
            title
          }
        }
      }
    `
  );
  const { title, description, author } = site.siteMetadata;
  const baseUrl = process.env.GATSBY_URL;
  const hashes = useMemo(
    () =>
      contentJson.header.reduce((acc, { to, title: t }) => {
        acc[to] = t;
        return acc;
      }, {}),
    [contentJson.header]
  );
  const subTitle = hashes[hash];
  const fullTitle = useMemo(() => (subTitle ? `${title} | ${subTitle}` : title), [subTitle, title]);

  return (
    <DefHelmet htmlAttributes={{ lang: 'de' }} defer={false}>
      <title>{fullTitle}</title>
      <meta name="title" content={fullTitle} />
      <meta name="description" content={description} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={`${baseUrl}/`} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={fullTitle} />
      <meta property="og:image" content={`${baseUrl}${file.publicURL}`} />
      <meta property="og:image:alt" content="open_graph_image" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:site" content={`${baseUrl}/`} />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${baseUrl}${file.publicURL}`} />
      <link rel="canonical" href={`${baseUrl}/`} />
    </DefHelmet>
  );
};

Helmet.propTypes = {
  hash: PropTypes.string.isRequired,
};

export default Helmet;
